import './App.css';
import React from 'react';
import face_img from './images/face.png'
import face_img_2 from './images/face2.png'
import body_img from './images/body.png'

function App() {
  return (
    <div className="App">
      <div className='viewport'>
        <iframe title="main audio track" width="1" height="1" wmode="transparent" src="//www.youtube.com/embed/a6SMppu5Bf0?autoplay=1&loop=1&playlist=a6SMppu5Bf0" frameborder="0"></iframe>
        <Servant src_body={body_img} src_face={face_img}/>
        <TextBox dialogue="To think that you of all people have tapped the depths of my power. Are you a barbarian, or just a fool, that you do not fear death by poisoning?" name="Semiramis" />
      </div>
    </div>
  );
}

class Servant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      body_img: this.props.src_body,
      face_img: this.props.src_face,
    }
  }
  render() {
    return (
      <div class="Servant" onClick={() => this.setState({ face_img: face_img_2 })}>
        <Body src={this.state.body_img}></Body>
        <Face src={this.state.face_img}></Face>
      </div>
    )
  }
}

class Face extends React.Component {
  render() {
    return (
      <div className='Face' style={{backgroundImage: 'url(' + this.props.src +')'}}></div>
    )
  }
}

class Body extends React.Component {
  render() {
    return (
      <div className='Body' style={{backgroundImage: 'url('+ this.props.src + ')'}}></div>
    )
  }
}

class TextBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.name,
      dialogue: this.props.dialogue
    }
  }
  render() {
    return (
      <div id='TextBox' className='TextBox'>
        <div className='BoxContent'>
          <Name name={this.state.name} />
          <Dialogue dialogue={this.state.dialogue} />
        </div>
      </div>
    )
  }
}

class Name extends React.Component {
  render() {
    return (
      <div className='Name'> {this.props.name} </div>
    )
  }
}

class Dialogue extends React.Component {
  render() {
    return (
      <span className='Dialogue'> {this.props.dialogue} </span>
    )
  }
}
export default App;
